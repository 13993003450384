.container.photo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-between;
  align-items: stretch;
}
.photo-about-container {
  display: inline-block;
  position: relative;
  height: 300px;
  width: 200px;
  background-repeat: no-repeat;

  &:hover {
    .photo-about-v, .photo-about-h {
      display: block;
    }

    img {
      display: none;
    }
  }

  .photo-about {
    position: absolute;
    color: white;
    font-size: large;
    display: none;
    text-shadow: 1px 1px 2px black, 0 0 1em black;

    &-v {
      writing-mode: tb-rl;
      transform: rotate(180deg);
      top: 0;
      padding: 20px 5px;
      text-align: end;
    }

    &-h {
      text-align: left;
      bottom: 0;
      padding: 14px;
    }
  }
}