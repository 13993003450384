.blog-item {
  a {
    color: inherit;
  }
  p {
    margin: .5rem 0;
  }
  h4, h3, h5 {
    text-transform: uppercase;
    margin: 0;
  }
  img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }
}