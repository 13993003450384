@charset "UTF-8";

$main-dark-color: black;

// Color
@import "components/color-variables";
@import "~materialize-css/sass/components/color-classes";

// Variables;
@import "components/variables";

// Reset
@import "~materialize-css/sass/components/normalize";

// components
@import "~materialize-css/sass/components/global";
@import "~materialize-css/sass/components/badges";
@import "~materialize-css/sass/components/icons-material-design";
@import "components/grid";
@import "components/navbar";
@import "~materialize-css/sass/components/typography";
@import "~materialize-css/sass/components/transitions";
@import "~materialize-css/sass/components/cards";
@import "~materialize-css/sass/components/toast";
@import "~materialize-css/sass/components/tabs";
@import "~materialize-css/sass/components/tooltip";
@import "~materialize-css/sass/components/buttons";
@import "components/dropdown";
@import "~materialize-css/sass/components/waves";
@import "components/modal";
@import "~materialize-css/sass/components/collapsible";
@import "~materialize-css/sass/components/chips";
@import "~materialize-css/sass/components/materialbox";
@import "~materialize-css/sass/components/forms/forms";
@import "~materialize-css/sass/components/table_of_contents";
@import "components/sidenav";
@import "~materialize-css/sass/components/preloader";
@import "~materialize-css/sass/components/slider";
@import "components/carousel";
@import "~materialize-css/sass/components/tapTarget";
@import "~materialize-css/sass/components/pulse";
@import "~materialize-css/sass/components/datepicker";
@import "~materialize-css/sass/components/timepicker";

@import "extra/comparison-slider";
@import "extra/gallery";
@import "extra/luminous";
@import "extra/portfolio";
@import "extra/services";
@import "extra/modal";
@import "extra/about";
@import "extra/blog";
@import "extra/vr";

@import "~luminous-lightbox/dist/luminous-basic.css";

body {
  background-color: $main-dark-color;
  font-weight: 300;
}

ul.col2 {
  flex-wrap: wrap;
  display: flex;

  li {
    flex: 1 0 50%;
    position: relative;
    padding-left: 30px;

    &:before {
      content: '\2022';
      line-height: $h6-fontsize;
      position: absolute;
      left: 0;
      margin: ($h6-fontsize / 1.5) 0 ($h6-fontsize / 2.5) 0;
      font-size: 3rem;
      width: 30px;
      text-align: center;
    }

    &.no-bullet {
      &:before {
        visibility: hidden;
      }
    }
  }
}

.col.vr-img {
  position: relative;
  height: 100%;
  padding: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url('../images/vr_glass.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
    padding: 30px 30px 30px 0;
  }
}

.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

.row-white {
  position: relative;
  background-color: white;

  .collapsible-body {
    padding: 2px;
  }

  //.col:first-child {
  //  padding-right: 0;
  //}
  //
  //.col:last-child {
  //  padding-left: 0;
  //}
}

.col-gallery {
  padding: 2px 2px 2px 2px !important;
}

@media #{$medium-and-up} {
  .row-white {
    &-vr {
      height: 400px;
    }
  }
}

@media #{$small-and-down} {
  .row-white {
    .col.vr-img:after {
      position: inherit;
      height: 400px;
      display: block;
      padding: 0 30px 30px 30px;
      background-position: center;
    }
  }
}

.row-bordered {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .col:not(:last-child) {
    border-right: 3px solid white;
  }
}

.footer {
  color: white;

  i.fa-whatsapp {
    color: #25d366;
  }

  i.fa-instagram {
    color: #c13584;
  }

  i.fa-telegram {
    color: #0088cc;
  }

  i.fa-facebook {
    color: #3b5998;
  }

  i.fa-vk {
    color: #4c75a3;
  }

  &-social {
    font-size: 32px;
    padding-bottom: 20px;

    a:not(:last-child) {
      margin-right: 10px;
    }

    a {
      background-color: white;
      display: inline-block;
      line-height: 45px;
      width: 45px;
      text-align: center;
      border-radius: 22.5px;
    }
  }
}

.bg-wrapper {
  position: relative;
  height: 600px;
}

.bg-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;

  & ~ * {
    color: white !important;
    font-weight: normal;

    &.index-info-card {
      h4 {
        text-transform: uppercase;
      }

      padding: 80px 0 !important;

      a {
        color: white !important;

        &.btn-trans {
          font-size: 15px;
        }
      }

      ul {
        li {
          line-height: 1.8rem;
        }

        a {
          font-weight: 100;
        }
      }
    }
  }

  &-img {
    position: absolute;
    top: 50%;
    left: 50%;

    width: auto;
    height: auto;

    max-height: none;
    max-width: none;

    min-height: 100%;
    min-width: 100%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    z-index: -1;
  }
}

.btn-high {
  color: black;
  background-color: #eed694;
  border-radius: $button-height / 2;
}

.btn-trans {
  &.white-text {
    border-color: white;
  }

  &.black-text {
    border-color: black;

    &:hover {
      background-color: rgba(0, 0, 0, .2);
    }
  }

  background-color: transparent;
  border: 2px solid;
  line-height: $button-height - 2px;
  font-weight: 500;
  border-radius: $button-height / 2;

  &-no-round {
    border-radius: 4px !important;
  }

  &:hover {
    background-color: rgba(255, 255, 255, .2);
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.lum-lightbox {
  z-index: 999;

  button:focus {
    background-color: inherit;
  }
}

.lightbox-item {
  cursor: zoom-in;
}

.cke_editable {
  background-color: white;
}

.fa.fa-instagram {
  color: #c13584;
}

.parallax-container.portfolio {
  height: 100vh;
}

.row.portfolio-head-img {
  padding-bottom: 0;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

@media #{$small-and-down} {
  .btn-trans {
    font-size: 0.8rem;
  }
  ul.col2 {
    flex-direction: column;
  }

  .bg-wrapper {
    height: 400px;
  }

  .row-white {
    .col {
      &.index-info-card {
        padding: 1rem;
      }

      padding: 0
    }
  }

  .row-bordered {
    margin-top: 0;
    margin-bottom: 1rem;

    .col:not(:last-child) {
      padding-bottom: ($h5-fontsize / 1.5);
      border-right: 0;
      border-bottom: 3px solid white;
    }
  }
}

.hoverable2 {
  position: relative;
  display: block;
  flex-grow: 1;

  img {
    object-fit: cover;
    vertical-align:text-bottom;
  }

  &:hover {
    .info {
      position: absolute;
      display: flex;
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      font-weight: bold;

      i {
        vertical-align: middle;
        line-height: 20px;
      }

      text-shadow: 0 0 0.5em black;
    }
  }

  .info {
    height: 0;
    background: rgba(0, 0, 0, 0);
    transition: background .3s ease-out;

    display: none; // make us of Flexbox
    align-items: center; // does vertically center the desired content
    justify-content: center; // horizontally centers single line items
    text-align: center; // optional, but helps horizontally center text that breaks in
  }

}

.see-more {
  line-height: 46px;
  height: 50px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ng-hide {
  display: none !important;
}

