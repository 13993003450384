@font-face {
    font-family: 'Nunito';
    src: local('Nunito Light'), local('Nunito-Light'), url('Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    src: local('Nunito Regular'), local('Nunito-Regular'), url('Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Nunito';
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'), url('Nunito-SemiBold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}