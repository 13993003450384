.vr-gap {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 0;

  &-item {
    flex: 1 1 calc((100% / 2) - 4rem);
    &:last-child {
      //flex-grow: 0;
      //flex-basis: calc((100% / 3) - 4rem);
    }
  }

  img {
    width: 100%;
    object-fit: cover;
  }

}

//@media #{$large-and-up} {
//  .vr-gap {
//    img {
//      width: 400px !important;
//    }
//  }
//}
//
@media #{$extra-large-and-up} {
  .vr-gap {
    &-item {
      flex: 1 1 calc((100% / 3) - 4rem)
    }
  }
  .vr-gallery {
    .container {
      width: 80%
    }
  }
}

@media #{$medium-and-down} {
  .vr-gap {
    &-item {
      flex: 1 1 calc((100% / 2) - 4rem)
    }
  }
}

@media #{$small-and-down} {
  .vr-gap {
    &-item {
      flex: 1 1 calc((100% / 1) - 4rem)
    }
  }
}

