.lum-close-button {
  cursor: pointer;
}

.lum-lightbox-inner img {
 @extend .z-depth-2
}

@media (max-width: 460px) {
  .lum-lightbox-inner img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
