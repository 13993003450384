.row.after-packets {
  background-color: #e6e7e9;
}
.packets {
  .packet-wrap {
    margin: 0;
    padding: 0 2px;
    padding-bottom: 4px;
    min-height: 360px;

    &:first-of-type {
      .packet {
        height: auto;
      }
    }
  }

  .packet {
    background-color: #57585a;
    padding: 0.75rem;
    height: auto;

    .pre-title {
      margin: 0;
      font-size: smaller;
    }

    .title {
      margin: 0;
      font-weight: bold;
    }

    .area-size {
      font-weight: bold;
      font-size: x-large;
    }
  }
}

.circles {
  img.circle {
    padding: 2rem;
  }

  .col {
    padding: 0 2rem;
    margin-bottom: 2rem;
  }
}

@media #{$medium-and-down} {
  .circles {
    img.circle {
      padding: 1rem;
    }
  }
}

.tiles {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start center;
  align-items: flex-start;

  .tile {
    &:not(:first-child) {
      margin-left: 20px;
    }

    width: 100px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.services-person {
  align-items: flex-start;
  color: white;
  background-color: #4e4e4d;
  height: 500px;

  .image {
    padding: 0;
    position: relative;
    height: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-image: url('../images/2_services_person_1.jpg');
      background-repeat: no-repeat;
      background-size: auto;
    }
  }

  .text {
    padding: 3rem;
  }
}

@media #{$small-and-down} {
  .services-person {
    height: 897px;
    .image {
      height: 90vw;
      background-size: 100%;
    }
  }
}