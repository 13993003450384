#modal-project-example, #modal-cost-example {
  width: 70%;
  height: 90%;

  .modal-content {
    overflow: hidden;
    height: 100%;

    .pdf-embed {
      height: calc(100% - 23px - 24px);
    }
  }
}

#modal-design-detail {
  .modal-content {
    padding: 0;
  }
}

#modal-service-detail {
  padding: 2rem;

  b {
    font-weight: bold;
  }

  ul {
    li {
      list-style-type: decimal;
    }
  }
}

@media #{$small-and-down} {
  #modal-project-example, #modal-cost-example {
    width: 90%;

    .modal-content {
      overflow: auto;
      padding: 5px;

      .col.s12 {
        height: 50% !important;
      }
    }
  }
}

//.modal-content {
//  .carousel-item {
//    width: 100%;
//    height: 100%;
//  }
//}