.ComparisonSliderWrapper {
  //margin: 0 -40px;
  padding-top: 10px;
  padding-bottom: 10px;

  .ComparisonSlider {
    width: 100%;
    height: 380px;

    &.white {
      .ComparisonSlider__Handle::before,
      .ComparisonSlider__Handle::after {
        background-color: white !important;
      }
    }
    &.black {
      .ComparisonSlider__Handle {
            background: url(data:image/svg+xml,%3Csvg%20fill=%22%23000%22%20preserveAspectRatio=%22none%22%20viewBox=%220%200%2046%2046%22%20xmlns=%22http://www.w3.org/2000/svg%22%3E%3Cpath%20d=%22m39.808%2023-10%208v-16z%22/%3E%3Cpath%20d=%22m6.192%2023%2010-8v16z%22/%3E%3C/svg%3E) 50% 50% / 100% 100%;
      }
      .ComparisonSlider__Handle::before,
      .ComparisonSlider__Handle::after {
        background-color: black !important;
      }
    }
  }

  .ComparisonSlider__Handle {
    border-radius: 0;
    width: 44px;
    height: 44px;
    border: 0;
  }

  .ComparisonSlider__Handle::before,
  .ComparisonSlider__Handle::after {
    background-color: white;
  }

  .ComparisonSlider__Handle::before {
    margin-bottom: 4px;
  }

  .ComparisonSlider__Handle::after {
    margin-top: 4px;
  }
}

