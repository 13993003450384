.portfolio-menu {
  .col {
    padding: 0;
  }
  nav > * {
    padding-left: 1rem;
    height: 100%;
  }
  nav {
    .nav-content.main-tags a {
      font-size: 1.2rem !important;
    }
  }
}