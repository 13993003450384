.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  &-gap {
    gap: 2px;

    img.img-grow {
      border: 5px solid white;
    }
  }

  &-zoom {
    img.img-grow:hover {
      transition: transform .2s;
      transform: scale(1.1);
    }
  }

  li {
    height: 40vh;
  }

  li:last-child {
    // There's no science in using "10" here. In all my testing, this delivered the best results.
    flex-grow: 10;
  }

  img {
    max-height: 100%;
    min-width: 100%;
    object-fit: contain;
    vertical-align: bottom;
  }

  // ADVANCED
  // Portrait
  @media (max-aspect-ratio: 1/1) {
    li {
      height: 30vh;
    }
  }

  // Short screens

  @media (max-height: 480px) {
    li {
      height: 80vh;
    }
  }

  // Smaller screens in portrait

  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
    & {
      flex-direction: row;

      li {
        height: auto;
        width: 100%;
      }

      img {
        width: 100%;
        max-height: 75vh;
        min-width: 0;
      }
    }
  }
}

.index-portfolio-gallery {
  position: relative;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;
  padding-right: 2px;
  padding-left: 2px;

  .column {
    flex: 0 0 50%;
    padding: 0 2px;

    img {
      background-color: white;
      width: 100%;
      margin-top: 4px;
      vertical-align: middle;
    }
  }

  .btn {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    text-shadow: 1px 1px 2px black, 0 0 1em black;
  }

  .hoverable2:hover .info {
    border-top: 4px solid white;
  }
}


.col.index-portfolio-col {
  padding: 0;
}

@media #{$small-and-down} {
  .col.index-portfolio-col {
    padding: 0;
    .index-portfolio-gallery {
      .column {
        flex: 0 0 100%;
      }
    }
  }
}

.index-gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: center;

  .item {
    margin: 5px;
    position: relative;
    img {
      display: block;
    }
  }
}