//$carousel-comparison-height: 100vh;
$carousel-comparison-height: 700px;

.carousel {
  &.carousel-slider.carousel-comparison {
    height: $carousel-comparison-height;
    //margin-bottom: 1rem;

    .ComparisonSliderWrapper .ComparisonSlider {
      height: $carousel-comparison-height;
    }
    .ComparisonSlider__After, .ComparisonSlider__Before {
      background-size: cover;
      background-repeat: no-repeat;
      &.contain {
        background-size: contain !important;
      }
    }

    .carousel-fixed-item {
      pointer-events: none;
      width: 100%;
      line-height: 25px;
      top: calc(50% - 25px / 2);

      .carousel-next, .carousel-prev {
        pointer-events: all;
        background-color: white;
        border-radius: 50%;
        border: 2px solid black;
        margin: 0 10px;
        cursor: pointer;
      }

      i.material-icons {
        font-size: 1.4rem;
      }
    }
  }

  &.carousel-slider {
    top: 0;
    left: 0;

    .carousel-fixed-item {
      &.with-indicators {
        bottom: 68px;
      }

      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      z-index: 1;
    }

    .carousel-item {
      width: 100%;
      height: 100%;
      min-height: $carousel-height;
      position: absolute;
      top: 0;
      left: 0;

      h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
      }

      p {
        font-size: 15px;
      }
    }
  }

  overflow: hidden;
  position: relative;
  width: 100%;
  height: $carousel-height;
  perspective: 500px;
  transform-style: preserve-3d;
  transform-origin: 0% 50%;

  .carousel-item {
    visibility: hidden;
    width: $carousel-item-width;
    height: $carousel-item-height;
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      width: 100%;
    }
  }

  .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;

    .indicator-item {
      &.active {
        background-color: #fff;
      }

      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 8px;
      width: 8px;
      margin: 24px 4px;
      background-color: rgba(255, 255, 255, .5);

      transition: background-color .3s;
      border-radius: 50%;
    }
  }

  // Materialbox compatibility
  &.scrolling .carousel-item .materialboxed,
  .carousel-item:not(.active) .materialboxed {
    pointer-events: none;
  }

  .btn-trans {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.carousel.carousel-index {
  height: calc(100vh - 35px);
  img {
    height: 100%;
  }

  .carousel-fixed-item {
    text-shadow: 1px 1px 2px black, 0 0 1em black;

    .btn:not(:first-child) {
      margin-left: 10px;
    }
  }
}

@media #{$extra-large-and-up} {
  .carousel.carousel-index {
    .carousel-item img {
      object-fit: cover;
      max-width: 100%
    }
  }
}

@media #{$medium-and-down} {
  .carousel.carousel-index {
    //height: 700px;
  }
}

@media #{$small-and-down} {
  .carousel.carousel-index {
    //height: 500px;

    img {
      object-fit: cover;
    }

    .carousel-fixed-item {
      .btn {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .carousel.carousel-index {
    .carousel-fixed-item {
      .btn {
        display: block;
        margin: 10px;
      }
    }
  }
}


